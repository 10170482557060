<template>
  <div class="content-wrapper">
    <!-- REPORT -->
    <report-view ref="reportViewComponent" :isPopup="true"/>
    <!-- 필터영역 -->
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">카트종류</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    ref="searchCartKind"
                    v-model="searchFilterOptions.cartKind"
                    :dataSource="commonCodeOptions.cartKind"
                    :allowFiltering="false"
                    :fields="commonCodeOptions.fields"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">카트번호</div>
            <ul class="content">
              <li class="item">
                <input-text ref="searchCartNo" v-model="searchFilterOptions.cartNo" @keydown.enter="onClickSearch"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="radio"
                          v-model="searchFilterOptions.dsuseFlag"
                          :value="false"
                      />
                      <i></i>
                      <div class="label">사용</div>
                    </label>
                  </li>
                </ul>
              </li>
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="radio"
                          v-model="searchFilterOptions.dsuseFlag"
                          :value="true"
                      />
                      <i></i>
                      <div class="label">폐기</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSearch">조회</erp-button>
        </div>
      </div>
    </div>
    <!-- 본문 -->
    <div class="content-body">
      <article class="body-article" :class="'detail-view-opened'">
        <!-- ######## 목록 리스트 ########-->
        <div class="article-left">
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">{{gridField.title}}</div>
                <div class="header-caption">[ {{ gridField.count }} 건 ]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="print">
                    <erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="body-grid">
                <ejs-grid-wrapper
                    ref="grid"
                    v-bind ="initGrid"
                    @recordClick="onGridClick"
                    @actionComplete="gridComplete"/>
              </div>
            </div>
          </section>
        </div>
        <!-- ######## 상세본문 ########-->
        <div class="article-right">
          <section class="article-section" >
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">카트상세</div>
              </div>
            </div>
            <div class="section-body">
              <div class="article-section" style="height: 410px;">
                <div class="section-body">
                  <div class="article-left" style="width: 25%;">
                    <section class="article-section">
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">사진</div>
                        </div>
                      </div>
                      <div class="section-body" style="border: none;">
                        <div class="article-left" style="width: 100%;">
                          <div class="section-body" style="border: none;">
                            <div class="body-photo">
                              <div
                                  v-if="detailData.imagePath"
                                  id="previewImage"
                                  class="item"
                                  :style="{ backgroundImage:'url('+detailData.imagePath+')' }"
                              ></div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="article-left" style="width: 35%;">
                    <section class="article-section">
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">기본정보</div>
                        </div>
                      </div>
                      <div class="section-body" style="border: none;">
                        <div class="article-right" style="width: 100%;">
                          <div class="section-body" style="border: none;">
                            <article class="article body">
                              <div class="body-data">
                                <div class="data-outer">
                                  <div class="data-inner">
                                    <ul class="data-content">
                                      <li class="field voc-section">
                                        <div class="title">카트번호</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-text ref="cartNo" id="cartNo" v-model="detailData.cartNo" :disabled="true"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title required">카트명</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-text ref="cartName" id="cartName" v-model="detailData.cartName" :disabled="true"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title required">카트종류</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <ejs-dropdownlist
                                                    ref="cartKind"
                                                    v-model="detailData.cartKind"
                                                    :dataSource="commonCodeOptions.cartKind"
                                                    :allowFiltering="false"
                                                    :fields="commonCodeOptions.fields"
                                                    cssClass="body-data-dropdown"
                                                    :enabled="false"
                                                />
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title">구입일자</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-date format="YYYY-MM-DD" ref="purchsDate" id="purchsDate" v-model="detailData.purchsDate" :isBindingEmptyString="true" type="body-data" :disabled="true"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title">구입금액</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-number ref="purchsAmt" name="purchsAmt" v-model="detailData.purchsAmt" :min="0" :disabled="true"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title">사용년수</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-text ref="useYycnt" id="useYycnt" v-model="detailData.useYycnt" :min="0" :propMaxLength="3" :disabled="true"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title">거래처</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group input">
                                              <div class="form">
                                                <input-text ref="bcncName" id="bcncName" v-model="detailData.bcncName" :disabled="true"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title">관리번호</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-text ref="manageNo" id="manageNo" v-model="detailData.manageNo" :disabled="true"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <!-- 필수 : required -->
                                        <div class="title">용도</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-text ref="prpos" id="prpos" v-model="detailData.prpos" :disabled="true"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title">제작회사</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-text ref="mnfctCompany" id="mnfctCompany" v-model="detailData.mnfctCompany" :disabled="true"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title">공급회사</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-text ref="suplyCompany" id="suplyCompany" v-model="detailData.suplyCompany" :disabled="true"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title">모델명</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-text ref="modelName" id="modelName" v-model="detailData.modelName" :disabled="true"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title">형식</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-text ref="fom" id="fom" v-model="detailData.fom" :disabled="true"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title">사용연료</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <ejs-dropdownlist
                                                    ref="cartUseFuel"
                                                    v-model="detailData.cartUseFuel"
                                                    :dataSource="commonCodeOptions.cartUseFuel"
                                                    :allowFiltering="false"
                                                    :fields="commonCodeOptions.fields"
                                                    cssClass="body-data-dropdown"
                                                    :enabled="false"
                                                ></ejs-dropdownlist>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </article>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="article-right" style="width: 40%;">
                    <section class="article-section">
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">카트 상태</div>
                        </div>
                      </div>
                      <div class="section-body" style="border: none;">
                        <div class="body-grid">
                          <article class="article body">
                            <div class="body-data">
                              <div class="data-outer">
                                <div class="data-inner">
                                  <ul class="data-content">
                                    <li class="field voc-section">
                                      <div class="title required">카트상태</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <ejs-dropdownlist
                                                  ref="cartStatus"
                                                  v-model="detailData.cartStatus"
                                                  :dataSource="commonCodeOptions.cartStatus"
                                                  :allowFiltering="false"
                                                  :fields="commonCodeOptions.fields"
                                                  cssClass="lookup-condition-dropdown"
                                                  :enabled="false"
                                              ></ejs-dropdownlist>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field voc-section">
                                      <div class="title required">폐기여부</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group check">
                                            <ul class="check">
                                              <li>
                                                <label>
                                                  <input type="radio" v-model=" detailData.dsuseFlag" :value="false" :disabled="true"/>
                                                  <i></i>
                                                  <div class="label">사용</div>
                                                </label>
                                              </li>
                                              <li>
                                                <label>
                                                  <input type="radio" v-model="detailData.dsuseFlag" :value="true" :disabled="true"/>
                                                  <i></i>
                                                  <div class="label">폐기</div>
                                                </label>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field voc-section">
                                      <div class="title required">폐기일자</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-date format="YYYY-MM-DD" ref="dsuseDate" id="dsuseDate"  v-model="detailData.dsuseDate" :isBindingEmptyString="true" type="body-data" :disabled="true"/>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field remarks voc-section">
                                      <div class="title">비고(1)</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-textarea ref="remarks1" name="remarks1" v-model="detailData.remarks1" :disabled="true"/>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field remarks voc-section">
                                      <div class="title">비고(2)</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-textarea ref="remarks2" name="remarks2" v-model="detailData.remarks2" :disabled="true"/>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </article>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <div class="article-section" style="height:calc(100% - 410px)">
                <div class="section-body">
                  <div class="article-section">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">수리이력</div>
                        <div class="header-caption">[ {{ gridField2.count }} 건 ]</div>
                      </div>
                      <div class="header-right">
                        <ul class="header-button">
                          <li class="print">
                            <erp-button button-div="FILE" @click.native="onClickExcel2">Excel</erp-button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="section-body" style="border: none;">
                      <div class="body-grid table-side">
                        <ejs-grid-wrapper
                            ref="grid2"
                            v-bind ="initGrid2"
                            @actionComplete="gridComplete2"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>
    </div>
    <!-- 팝업 -->
  </div>
</template>

<script>

import commonMixin from "@/views/layout/mixin/commonMixin";
import ReportView from "@/components/common/report/ReportView.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import InputText from "@/components/common/text/InputText.vue";
import InputDate from "@/components/common/datetime/InputDate.vue";
import InputNumber from "@/components/common/InputNumber.vue";
import InputTextarea from "@/components/common/text/InputTextarea.vue";
import {getFormattedDate} from "@/utils/date";
import StockCartMng from "@/api/v2/StockManage/StockCartMng";

export default {
  name: "CartHistoryCard",
  mixins: [commonMixin, MessagePopupDialogMixin],
  components: {EjsGridWrapper, ErpButton, ReportView,
    InputText,
    InputDate,
    InputNumber,
    InputTextarea,
  },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      //조회 필드
      searchFilterOptions: {
        cartNo: "",
        cartKind: "",
        dsuseFlag: false,
      },
      //드롭다운 코드
      commonCodeOptions: {
        cartKind: commonCodesGetCommonCode("CART_KIND",false,true,"",""),
        cartUseFuel: commonCodesGetCommonCode("CART_USE_FUEL",false,true,"",""),
        cartStatus: commonCodesGetCommonCode("CART_STATUS",false,true,"",""),
        cartChckPart: commonCodesGetCommonCode("CART_CHCK_PART",false,true,"",""),
        cartRepairPart: commonCodesGetCommonCode("CART_REPAIR_PART",false,true,"",""),
        cartDefectRegn: commonCodesGetCommonCode("CART_DEFECT_REGN",false,true,"",""),
        fields: { text: "comName", value: "comCode" },
      },

      //그리드 필드
      gridField: {
        title: "카트목록",
        count : 0,
        dataSource: [],
      },
      gridField2: {
        title: "수리이력",
        count : 0,
        dataSource: [],
      },

      //상세 데이터 필드
      detailData: {
        cartId: null,
        cartNo: null,
        cartName: null,
        cartKind: null,
        purchsDate:null,
        purchsAmt: 0,
        useYycnt: null,
        bcncName: null,
        manageNo: null,
        prpos: null,
        mnfctCompany: null,
        suplyCompany: null,
        modelName: null,
        fom: null,
        cartUseFuel: null,
        cartStatus: null,
        dsuseFlag: false,
        dsuseDate: null,
        remarks1: null,
        remarks2: null,
        imagePath: null,
        imageName: null,
      },
    };
  },

  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    // 팝업시 메인화면 버튼의 단축키 조정 , 팝업이 없으면 false 리턴
    isPopupOpened() {
      return false;
    },
    initGrid() {
      return {
        columns: [
          { field: "cartId",       textAlign: 'center', allowEditing: false, width: 100,  visible: false, type: "string",  headerText: "카트ID", isPrimaryKey: true, },
          { field: "cartNo",       textAlign: 'center', allowEditing: false, width: 80,   visible: true,  type: "string",  headerText: "카트번호", },
          { field: "cartKind",     textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "카트종류",  isCommonCodeField: true, dataSource: this.commonCodeOptions.cartKind},
          { field: "cartName",     textAlign: 'center', allowEditing: false, width: 150,  visible: true,  type: "string",  headerText: "카트명", },
          { field: "cartStatus",   textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "카트상태",    isCommonCodeField: true, dataSource: this.commonCodeOptions.cartStatus},
          { field: "purchsDate",   textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "구입일자",
            valueAccessor: (field,data) => getFormattedDate(data[field]) },
          { field: "dsuseDate",    textAlign: 'center', allowEditing: false, width: 100,  visible: false, type: "string",  headerText: "폐기일자",
            valueAccessor: (field,data) => getFormattedDate(data[field]) },
          { field: "mnfctCompany", textAlign: 'center', allowEditing: false, width: 130,  visible: true,  type: "string",  headerText: "제작회사", },
          { field: "cartUseFuel",  textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "사용연료", isCommonCodeField: true, dataSource: this.commonCodeOptions.cartUseFuel},
          { field: "purchsAmt",    textAlign: 'right',  allowEditing: false, width: 100,  visible: false, type: "number",  headerText: "구입금액", isNumericType: true},
          { field: "remarks1",     textAlign: 'left',   allowEditing: false, width: 250,  visible: true,  type: "string",  headerText: "비고1", },
          { field: "remarks2",     textAlign: 'left',   allowEditing: false, width: 250,  visible: true,  type: "string",  headerText: "비고2", },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: true,
        pageSettings: { pageSize: 50 },
        resizeSettings: {mode:"Normal"},
      };
    },
    initGrid2() {
      return {
        columns: [
          { field: "repairId",       textAlign: 'center', allowEditing: false, width: 30,  visible: false, type: "string",  headerText: "수리ID", isPrimaryKey: true,  },
          { field: "cartNo",         textAlign: 'center', allowEditing: false, width: 30,  visible: false, type: "string",  headerText: "카트번호", },
          { field: "repairDate",     textAlign: 'center', allowEditing: false, width: 80,  visible: true,  type: "string",  headerText: "수리일자",
            valueAccessor: (field,data) => getFormattedDate(data[field]) },
          { field: "chckPart",       textAlign: 'center', allowEditing: false, width: 80,  visible: true, type: "string",  headerText: "점검부",    isCommonCodeField: true, dataSource: this.commonCodeOptions.cartChckPart},
          { field: "repairDiv",      textAlign: 'center', allowEditing: false, width: 80,  visible: true, type: "string",  headerText: "수리구분",   isCommonCodeField: true, dataSource: this.commonCodeOptions.cartRepairPart},
          { field: "defectRegn",     textAlign: 'center', allowEditing: false, width: 80,  visible: true, type: "string",  headerText: "고장부위",   isCommonCodeField: true, dataSource: this.commonCodeOptions.cartDefectRegn},
          { field: "remarks",        textAlign: 'Left',   allowEditing: false, width: 100, visible: true,  type: "string",  headerText: "수리내역", },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField2.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:false,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: { pageSize: 50 },
      };
    },
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
    // 기초 데이터 초기화
    this.clearDetailData();
  },
  async mounted() {
    // 기본 포커싱
    this.$refs.searchCartNo.focusIn();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //region ******************************* 버튼 이벤트 *******************************
    //조회
    async onClickSearch() {
      //상세 clear
      await this.clearDetailData();

      this.gridField.dataSource = await StockCartMng.getCartList(this.searchFilterOptions);
    },
    //엑셀 출력
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    onClickExcel2() {
      const cartNm = this.detailData.cartName;
      if(cartNm?.trim()){
        this.$refs.grid2.excelExport({fileName: cartNm+" 수리이력.xlsx"});
      }
    },
    //리포트 출력
    onClickPrint() {
    },
    //endregion
    //region ******************************* 상세 이벤트 *******************************
    //카트 상세 조회
    async searchDetail(code) {
      const args = {cartId:code};
      const file = await StockCartMng.getCartList(args);
      this.detailData = file[0];
    },
    //수리 목록 조회
    async searchRepair(code) {
      const args = {cartId:code};
      this.gridField2.dataSource = await StockCartMng.getCartRepairList(args);
    },
    //endregion
    //region ******************************* 공통 이벤트 *******************************
    async clearDetailData() {
      this.detailData.cartId = null;
      this.detailData.cartNo = null;
      this.detailData.cartName = null;
      this.detailData.cartKind = null;
      this.detailData.purchsDate = null;
      this.detailData.purchsAmt = 0;
      this.detailData.useYycnt = null;
      this.detailData.bcncName = null;
      this.detailData.manageNo = null;
      this.detailData.prpos = null;
      this.detailData.mnfctCompany = null;
      this.detailData.suplyCompany = null;
      this.detailData.modelName = null;
      this.detailData.fom = null;
      this.detailData.cartUseFuel = null;
      this.detailData.cartStatus = null;
      this.detailData.dsuseFlag = null;
      this.detailData.dsuseDate = null;
      this.detailData.remarks1 = null;
      this.detailData.remarks2 = null;
      this.detailData.imagePath = null;
      this.detailData.imageId = null;
    },
    //endregion
    //region ******************************* 그리드 이벤트 *******************************
    //그리드 셀 클릭
    async onGridClick(args){
      //상세 clear
      await this.clearDetailData();
      //상세 표시
      await this.searchDetail(args.rowData.cartId);
      //수리내역 표시
      await this.searchRepair(args.rowData.cartId);
    },
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    gridComplete2(){
      this.gridField2.count = this.$refs.grid2.getGridBatchCount();
    },
  }
};
</script>

<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
/* 날자필드 readonly 글자색상*/
body .body-data .data-content::v-deep .inputDate.disabled .inputDate-input input[type="text"] {color: #666 !important;}

body .appContent .body-article .article-left,body .appContent .body-article .article-right{transition:all .3s ease-out}
body .appContent .body-article .article-left{width:100%;}
body .appContent .body-article .article-right{margin-right: calc(-50% - 20px)}
body .appContent .body-article.detail-view-opened .article-left{width:calc(30% - 10px)}
body .appContent .body-article.detail-view-opened .article-right{width:calc(70% - 10px); margin:0}
body .body-photo {height :270px}
body .body-footer .section-header {display: block;position: relative;margin: 0px 0px;}
.voc-section {width: 100% !important;}
</style>
